<template>
  <div>
    <div class="text-end" style="margin-right: 19px">
      <div class="text-end mt-4" style="margin-right: 19px">
        Visualizzati
        <b v-if="record > 0">
          {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
          -
          {{
            rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
          }}
        </b>
        <b v-else> 0 </b>
        elementi di <b>{{ record }}</b>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        societa_privacy_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="societa_privacy_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsPrivacyTesSoc"
        @current-change="setCurrentPagePrivacyTesSoc"
      >
        <template v-slot:cell-cod_fisc="{ row: data }"
          ><router-link
            v-if="!readOnly"
            :to="`/persone/dettaglio-persona/privacy-persona/${data.id_persona}`"
            ><div class="tab-long">{{ data.cod_fisc }}</div></router-link
          >
          <div v-else>{{ data.cod_fisc }}</div></template
        >
        <template v-slot:cell-nominativo="{ row: data }"
          ><router-link
            v-if="!readOnly"
            :to="`/persone/dettaglio-persona/privacy-persona/${data.id_persona}`"
            ><div class="tab-long-xl">{{ data.nominativo }}</div></router-link
          >
          <div v-else>{{ data.nominativo }}</div></template
        >
        <template v-slot:cell-privacy_1="{ row: data }">
          <i
            :class="
              data.privacy_1
                ? 'bi bi-check-lg fw-bold text-success fs-2'
                : 'bi bi-x-lg fw-bold text-danger'
            "
          ></i>
        </template>
        <template v-slot:cell-privacy_2="{ row: data }">
          <i
            :class="
              data.privacy_2
                ? 'bi bi-check-lg fw-bold text-success fs-2'
                : 'bi bi-x-lg fw-bold text-danger'
            "
          ></i
        ></template>
        <template v-slot:cell-privacy_3="{ row: data }">
          <i
            :class="
              data.privacy_3
                ? 'bi bi-check-lg fw-bold text-success fs-2'
                : 'bi bi-x-lg fw-bold text-danger'
            "
          ></i
        ></template>
        <template v-slot:cell-privacy_4="{ row: data }">
          <i
            :class="
              data.privacy_4
                ? 'bi bi-check-lg fw-bold text-success fs-2'
                : 'bi bi-x-lg fw-bold text-danger'
            "
          ></i
        ></template>
        <template v-slot:cell-privacy_5="{ row: data }">
          <i
            :class="
              data.privacy_5
                ? 'bi bi-check-lg fw-bold text-success fs-2'
                : 'bi bi-x-lg fw-bold text-danger'
            "
          ></i
        ></template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "table-privacy-tes-soc",
  components: { Datatable },
  emits: ["getPrivacyTesSocietaList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsPrivacyTesSoc = (e) => {
      store.commit("setFetchRowsPrivacyTesSoc", e);
      emit("getPrivacyTesSocietaList");
    };
    const setCurrentPagePrivacyTesSoc = (page) => {
      store.commit("setCurrentPagePrivacyTesSoc", page);
      emit("getPrivacyTesSocietaList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnPrivacyTesSoc", columnName);
      store.commit("setSortOrderPrivacyTesSoc", order);
      emit("getPrivacyTesSocietaList");
    };

    const tableHeader = ref([
      {
        name: "Codice Fiscale",
        key: "cod_fisc",
      },
      {
        name: "Tesserato",
        key: "nominativo",
      },
      {
        name: "Paragrafo 1.A",
        key: "privacy_1",
        sortable: false,
      },
      {
        name: "Paragrafo 1.B",
        key: "privacy_2",
        sortable: false,
      },
      {
        name: "Paragrafo 1.C",
        key: "privacy_3",
        sortable: false,
      },
      {
        name: "Paragrafo 1.D",
        key: "privacy_4",
        sortable: false,
      },
      {
        name: "Paragrafo 1.E",
        key: "privacy_5",
        sortable: false,
      },
    ]);

    return {
      societa_privacy_list: computed(() =>
        store.getters.getStateFromName("resultssocieta_privacy_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedsocieta_privacy_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordsocieta_privacy_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statussocieta_privacy_list")
      ),

      currentPage: computed(() => store.getters.currentPagePrivacyTesSoc),
      rowsToSkip: computed(() => store.getters.rowsToSkipPrivacyTesSoc),
      fetchRows: computed(() => store.getters.fetchRowsPrivacyTesSoc),
      sortColumn: computed(() => store.getters.sortColumnPrivacyTesSoc),
      sortOrder: computed(() => store.getters.sortOrderPrivacyTesSoc),
      setFetchRowsPrivacyTesSoc,
      setCurrentPagePrivacyTesSoc,
      setSortOrderColumn,
      tableHeader,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>
