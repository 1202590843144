<template>
  <div>
    <TablePrivacyTesSoc
      @getPrivacyTesSocietaList="getPrivacyTesSocietaList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TablePrivacyTesSoc from "@/components/components-fit/società/privacy/TablePrivacyTesSoc.vue";

export default defineComponent({
  name: "privacy-societa",
  components: {
    TablePrivacyTesSoc,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Privacy Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipPrivacyTesSoc);
    const fetchRows = computed(() => store.getters.fetchRowsPrivacyTesSoc);
    const sortColumn = computed(() => store.getters.sortColumnPrivacyTesSoc);
    const sortOrder = computed(() => store.getters.sortOrderPrivacyTesSoc);

    const getPrivacyTesSocietaList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          stagione: stagione.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.SOCIETA_PRIVACY_LIST,
        itemName: "societa_privacy_list",
      });
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      getPrivacyTesSocietaList();
    });

    const resetFilters = () => {
      store.commit("resetFiltersPrivacyTesSoc");
      getPrivacyTesSocietaList();
    };
    getPrivacyTesSocietaList();

    return {
      getPrivacyTesSocietaList,
      resetFilters,
    };
  },
});
</script>
